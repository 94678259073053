import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import CatalogueWM from "../components/catalogue-wm"

export const query = graphql`
  query {
    allWashingMachinesJson {
      edges {
        node {
          id
          kg
          title
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

export default function WashingMachines({ data }) {
  const washingMachines = data.allWashingMachinesJson.edges
  return (
    <Layout>
      <Helmet>
        <title>Quartz - Auto Washing Machines</title>
      </Helmet>
      <CatalogueWM headerTitle="All Auto Washing Machines" items={washingMachines} />
    </Layout>
  )
}
